import {computed, ref, watch} from 'vue'
import { defineStore } from 'pinia'

export const useHintStore = defineStore('hint', () => {
  const dataForHint = ref({
    norm: 0,
    mod1: 0,
    mod2: 0,
    mod3: 0,
    mod4: 0,
    mod5: 0,
    mod6: 0
  })

  return {
    dataForHint
  }
})
