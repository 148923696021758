import {type Ref, ref} from 'vue'
import { defineStore } from 'pinia'

export const useScreenshotStore = defineStore('screenshot', () => {
  const createScreenshot: Ref<boolean> = ref(false);
  const format: Ref<"" | "png" | "jpeg"> = ref("");
  const screenshotName: Ref<string> = ref("");

  return {createScreenshot, format, screenshotName}
})
