<script setup lang="ts">
import { Chart } from 'chart.js/auto'
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import {
  getNormRiverCycle,
  getRiverCycle
} from '@/components/dataPage/components/ChartComponent/api/api'
import { useGlobalStore } from '@/stores/globalStore'
import { useI18n } from 'vue-i18n'
import {useCVSStore} from "@/stores/csvStore";

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

interface Props {
  selectedDisplayType: 'average' | 'river_cycle' | 'map'
  tabSelected: string
  period: string
  periodYears: string
  compare: string | null
}

const chartRef = ref(null)

const props = defineProps<Props>()
const globalStore = useGlobalStore()

let dataForChart = ref([])

const allZeros = computed(() => {
  return dataForChart.value.result?.map((el) =>
      getDataFromResponses(el, 'min')
  ).every(value => value === 0);
})

let chartSelector = null

const ukLabels = [
  'Січ.',
  'Лют.',
  'Бер.',
  'Квіт.',
  'Трав.',
  'Черв.',
  'Лип.',
  'Серп.',
  'Вер.',
  'Жовт.',
  'Лист.',
  'Груд.'
]
const enLabels = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.'
]

let chart = reactive<Chart<'line', any, unknown> | {}>({})

const tabs = {
  discharge: {
    en: 'Discharge',
    uk: 'Витрати води'
  },
  water_flow: {
    en: 'Water flow',
    uk: 'Об’єм стоку'
  },
  temperature: {
    en: 'Temperature',
    uk: 'Температура'
  },
  precipitation: {
    en: 'Precipitation',
    uk: 'Опади'
  },
  evapotranspiration: {
    en: 'Evapotranspiration',
    uk: 'Випаровування'
  },
  groundwater: {
    en: 'Groundwater',
    uk: 'Підземні води'
  },
  soil_water: {
    en: 'Soil water',
    uk: 'Вологість ґрунту'
  }
}

const models = {
  0: {
    en: 'Median',
    uk: 'Медіана'
  },
  1: {
    en: 'Mod.1 - EC-EARTH_HIRHAM5',
    uk: 'Мод.1 - EC-EARTH_HIRHAM5'
  },
  2: {
    en: 'Mod.2 - EC-EARTH_RACMO22E',
    uk: 'Мод.2 - EC-EARTH_RACMO22E'
  },
  3: {
    en: 'Mod.3 - IPSL-CM5A-MR_RCA4',
    uk: 'Мод.3 - IPSL-CM5A-MR_RCA4'
  },
  4: {
    en: 'Mod.4 - HadGEM-ES_RACMO22E',
    uk: 'Мод.4 - HadGEM-ES_RACMO22E'
  },
  5: {
    en: 'Mod.5 - HadGEM2-ES_RCA4',
    uk: 'Мод.5 - HadGEM2-ES_RCA4'
  },
  6: {
    en: 'Mod.6 - MPI-ESM-LR_RCA4',
    uk: 'Мод.6 - MPI-ESM-LR_RCA4'
  }
}

const period = {
  en: 'Period',
  uk: 'Період'
}

const refer = {
  en: 'Ref.',
  uk: 'Реф.'
}

const scenari = {
  en: 'Emissions scenario',
  uk: 'Сценарій викидів'
}

const getUnits = () => {
  let pidpis = null

  if (props.tabSelected === 'temperature') {
    pidpis = '°C'
  }

  if (currentLocale.value === 'uk') {
    if (props.tabSelected === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.tabSelected === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
      props.tabSelected !== 'discharge' &&
      props.tabSelected !== 'water_flow' &&
      props.tabSelected !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.tabSelected === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.tabSelected === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
      props.tabSelected !== 'discharge' &&
      props.tabSelected !== 'water_flow' &&
      props.tabSelected !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.units === 'relative' && props.tabSelected !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}

const loadChart = async () => {
  chartSelector = document.getElementById('chart')
  const ctx = chartSelector.getContext('2d')

  const bgColor = 'rgba(131, 196, 235, 0.7)'

  try {
    chart = new Chart(chartSelector as HTMLCanvasElement, {
      type: 'line',
      data: {
        labels: currentLocale.value === 'en' ? enLabels : ukLabels,
        datasets: [
          {
            order: 5,
            backgroundColor: bgColor,
            data: [],
            borderWidth: 0,
            pointRadius: 0
          },
          {
            order: 5,
            backgroundColor: bgColor,
            data: [],
            borderWidth: 0,
            pointRadius: 0,
            fill: '-1'
          },
          {
            order: 1,
            borderColor: 'rgba(49, 130, 189, 1)',
            backgroundColor: bgColor,
            data: [],
            borderWidth: 3,
            pointRadius: 0
          }
        ]
      },
      options: {
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: true,
              color: '#e1e1e1',
              lineWidth: 1,
              drawTicks: true,
              offset: false
            },
            ticks: {
              font: {
                size: 15,
                weight: 600
              },
              color: '#6C7681'
            }
          },
          y: {
            grace: '1%',
            ticks: {
              font: {
                size: 16,
                weight: 'bold'
              },
              color: '#6C7681',
              padding: 14,
              callback: function (value, index, values) {
                const pidpis = getUnits()

                if (index === values.length - 1) {
                  return `${value} ${pidpis}`
                }
                return value
              }
            },

            stacked: false,
            beginAtZero: true,
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              color: '#D3DCE4',
              lineWidth: 2
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            mode: 'nearest',
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            bodyColor: '#000',
            borderColor: '#ccc',
            borderWidth: 0,
            external: function (context) {
              const tooltipModel = context.tooltip
              if (!tooltipModel.opacity) {
                return
              }

              let columnIndex =
                tooltipModel.dataPoints.length > 0 ? tooltipModel.dataPoints[0].dataIndex : null
              let datasetIndex =
                tooltipModel.dataPoints.length > 0 ? tooltipModel.dataPoints[0].datasetIndex : null

              let color
              if (tooltipModel.dataPoints.length > 0) {
                if (context.chart.data.datasets[datasetIndex].borderColor !== undefined) {
                  color = context.chart.data.datasets[datasetIndex].borderColor
                } else {
                  color = context.chart.data.datasets[datasetIndex].backgroundColor
                }
              } else {
                color = '#FFFFFF'
              }

              let tooltipEl = document.getElementById('chartjs-tooltip')
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                tooltipEl.innerHTML =
                  "<div style='padding: 5px; border-radius: 4px' class='tooltip-inner'></div>"
                document.body.appendChild(tooltipEl)
              }

              tooltipEl.style.boxShadow = '2px 2px 6px rgba(0,0,0,0.2)'

              const position = context.chart.canvas.getBoundingClientRect()
              tooltipEl.style.background = 'white'
              tooltipEl.style.opacity = 1
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 50 + 'px'
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY - 30 + 'px'
              tooltipEl.style.fontFamily = tooltipModel.options.bodyFont.family
              tooltipEl.style.fontSize = tooltipModel.options.bodyFont.size + 'px'
              tooltipEl.style.fontStyle = tooltipModel.options.bodyFont.style
              tooltipEl.style.padding =
                tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px'
              tooltipEl.style.pointerEvents = 'none'

              let innerHtml = ''
              if (props.compare === 'rcp45' || props.compare === 'rcp85') {
                innerHtml += `<div style="margin-bottom: 4px; font-size: 14px; color: #666;">
                                  <span style="height: 16px; width: 16px; border-radius: 16px; background-color: ${color}; display: inline-block; margin-right: 5px;"></span>
                                  <span style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? tabs[props.tabSelected].en : tabs[props.tabSelected].uk} ${tooltipModel.body[0].lines[0]} ${getUnits()}</span>
                              </div>`
              } else {
                innerHtml += `<div style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? tabs[props.tabSelected].en : tabs[props.tabSelected].uk} ${tooltipModel.body[0].lines[0]} ${getUnits()}</div>`
              }
              innerHtml += `
                              <div style="font-family: Manrope;font-size: 16px;font-weight: 700;line-height: 24px;letter-spacing: 0.1px;color: rgba(8, 47, 87, 1);">${currentLocale.value === 'en' ? enLabels[columnIndex] : ukLabels[columnIndex]}</div>
                              <div style="font-family: Manrope;
                                  font-size: 15px;
                                  font-weight: 400;
                                  line-height: 22.5px;
                                  letter-spacing: 0.1px;
                              ">${currentLocale.value === 'en' ? period.en : period.uk} ${props.periodYears}${currentLocale.value === 'en' ? '' : ' рр.'};</div>
                              <div style="font-family: Manrope;
                                  font-size: 15px;
                                  font-weight: 400;
                                  line-height: 22.5px;
                                  letter-spacing: 0.1px;
                              ">${currentLocale.value === 'en' ? refer.en : refer.uk} 1991-2020${currentLocale.value === 'en' ? '' : ' рр.'};</div>
                              `
              const rcp = globalStore.sceneriesAtmosphere === 'rcp45' ? 'RCP4.5' : 'RCP8.5'
              const rcp1 = globalStore.sceneriesAtmosphere === 'rcp45' ? 'RCP8.5' : 'RCP4.5'

              innerHtml += `<div style="font-family: Manrope;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 22.5px;
                  letter-spacing: 0.1px;
                  ">${currentLocale.value === 'en' ? scenari.en : scenari.uk} - <b style="font-weight: bold">${datasetIndex > 3 ? rcp1 : rcp}</b>;</div>`

              const tableRoot = tooltipEl.querySelector('.tooltip-inner')
              tableRoot.innerHTML = innerHtml
            }
          }
        }
      }
    }) as any // Chart<'line', any, unknown> , but problem with TS
  } catch (e) {
    console.log(e)
  }
}

const getParameterToDisplay = computed(() => {
  if (props.tabSelected === 'evapotranspiration') {
    if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
    if (globalStore.evaporation === 'fact') return 'evapotranspiration'
  }

  return props.tabSelected
})

const getDataFromResponses = (el, type) => {
  let result
  result = el[getParameterToDisplay.value]
  if (getParameterToDisplay.value === 'water_flow') {
    if(el['water_flow_outlet'] === null){
      result = el['water_flow_area']
    } else {
      result = el['water_flow_outlet']
    }
  }
  if (type) {
    if (getParameterToDisplay.value === 'water_flow') {
      if (el['water_flow_outlet'].min === 0 && el['water_flow_outlet'].max === 0) {
        result = el['water_flow_area']
      }
    }

    result = result[type]
  }
  return result
}

const updateChart = async () => {
  chart.data.datasets[0].data = dataForChart.value.result.map((el) =>
    getDataFromResponses(el, 'min')
  )
  chart.data.datasets[1].data = dataForChart.value.result.map((el) =>
    getDataFromResponses(el, 'max')
  )
  chart.data.datasets[2].data = dataForChart.value.median.map((el) => getDataFromResponses(el))

  if (props.compare === 'norm' && chart.data.datasets.length === 3) {
    chart.data.datasets.push({
      type: 'line',
      borderColor: '#212121',
      order: 3,
      borderWidth: 3,
      borderDash: [5, 5],
      fill: false,
      data: (await getNormRiverCycle(globalStore.selectedBasinCode,
          props.tabSelected)).map((el) =>
        getDataFromResponses(el.data)
      ),
      pointRadius: 0
    })
  } else if (props.compare === 'norm' && chart.data.datasets.length === 4) {
    chart.data.datasets[3].data = (await getNormRiverCycle(globalStore.selectedBasinCode,
        props.tabSelected)).map(
      (el) => getDataFromResponses(el.data)
    )
  } else if (props.compare === 'norm' && chart.data.datasets.length > 4) {
    chart.data.datasets = [
      chart.data.datasets[0],
      chart.data.datasets[1],
      chart.data.datasets[2],
      chart.data.datasets[3]
    ]
    chart.data.datasets[3].data = (await getNormRiverCycle(globalStore.selectedBasinCode,
        props.tabSelected)).map(
      (el) => getDataFromResponses(el.data)
    )
  }

  if (
    (props.compare === 'rcp45' || props.compare === 'rcp85') &&
    chart.data.datasets.length === 3
  ) {
    chart.data.datasets.push(
      {
        order: 5,
        backgroundColor: 'rgba(186, 228, 179, 0.7)',
        data: [],
        borderWidth: 0,
        pointRadius: 0
      },
      {
        order: 5,
        backgroundColor: 'rgba(186, 228, 179, 0.7)',
        data: [],
        borderWidth: 0,
        pointRadius: 0,
        fill: '-1'
      },
      {
        order: 1,
        borderColor: '#74C476',
        backgroundColor: '#74C476',
        data: [],
        borderWidth: 3,
        pointRadius: 0
      }
    )

    const compareData = await getRiverCycle(
      globalStore.selectedBasinCode,
      globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
      props.periodYears,
      globalStore.units,
        props.tabSelected
    )

    chart.data.datasets[3].data = compareData.result.map((el) => getDataFromResponses(el, 'min'))
    chart.data.datasets[4].data = compareData.result.map((el) => getDataFromResponses(el, 'max'))
    chart.data.datasets[5].data = compareData.median.map((el) => getDataFromResponses(el))

    console.log('compareData', compareData)
  } else if (
    (props.compare === 'rcp45' || props.compare === 'rcp85') &&
    chart.data.datasets.length > 3
  ) {
    const compareData = await getRiverCycle(
      globalStore.selectedBasinCode,
      globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
      props.periodYears,
      globalStore.units,
        props.tabSelected
    )

    chart.data.datasets[3].data = compareData.result.map((el) => getDataFromResponses(el, 'min'))
    chart.data.datasets[4].data = compareData.result.map((el) => getDataFromResponses(el, 'max'))
    chart.data.datasets[5].data = compareData.median.map((el) => getDataFromResponses(el))
  }

  chart.data.labels = currentLocale.value === 'en' ? enLabels : ukLabels
  chart.update()
}

watch(
  [
    () => globalStore.evaporation,
    () => props.tabSelected,
    () => dataForChart.value,
    () => currentLocale.value
  ],
  () => {
    updateChart()
  }
)

watch(
  [
    () => globalStore.selectedBasinCode,
    () => globalStore.sceneriesAtmosphere,
    () => props.period,
    () => props.periodYears,
    () => globalStore.units,
    () => globalStore.territory
  ],
  async () => {
    dataForChart.value = await getRiverCycle(
      globalStore.selectedBasinCode,
      globalStore.sceneriesAtmosphere,
      props.periodYears,
      globalStore.units,
        props.tabSelected
    )
  },
  {
    immediate: true
  }
)

onMounted(() => {
  loadChart()
})

const closeChart = (event) => {
  if (!event.target.closest('#chart')) {
    // if(!chart) return
    // chart.data.datasets[0].backgroundColor = chart.data.datasets[0].backgroundColor.map((el, index) => index === 0 ? gradientMedian : gradient);
    // if(chart.data.datasets.length > 1){
    //   chart.data.datasets[1].backgroundColor = chart.data.datasets[0].backgroundColor.map((el, index) => index === 0 ? gradientMedianGreen : gradientGreen);
    // }

    const element = document.querySelector('#chartjs-tooltip')
    if (element) {
      element.parentNode.removeChild(element)
    }

    chart.update()
  }
}

watch(
  () => props.compare,
  async (newVal, oldVal) => {
    if (oldVal === 'norm' || oldVal === 'rcp45' || oldVal === 'rcp85') {
      chart.data.datasets = [chart.data.datasets[0], chart.data.datasets[1], chart.data.datasets[2]]
    }

    if (newVal === 'norm') {
      dataForChart.value = await getRiverCycle(
        globalStore.selectedBasinCode,
        globalStore.sceneriesAtmosphere,
        props.periodYears,
        globalStore.units,
          props.tabSelected
      )

      chart.data.datasets.push({
        type: 'line',
        order: 3,
        borderColor: '#212121',
        borderWidth: 3,
        borderDash: [5, 5],
        fill: false,
        data: (await getNormRiverCycle(globalStore.selectedBasinCode, props.tabSelected)).map((el) =>
          getDataFromResponses(el.data)
        ),
        pointRadius: 0
      })
    }

    if (newVal === 'rcp45' || newVal === 'rcp85') {
      chart.data.datasets.push(
        {
          order: 6,
          backgroundColor: 'rgba(186, 228, 179, 0.7)',
          data: [],
          borderWidth: 0,
          pointRadius: 0
        },
        {
          order: 6,
          backgroundColor: 'rgba(186, 228, 179, 0.7)',
          data: [],
          borderWidth: 0,
          pointRadius: 0,
          fill: '-1'
        },
        {
          order: 2,
          borderColor: '#74C476',
          backgroundColor: '#74C476',
          data: [],
          borderWidth: 3,
          pointRadius: 0
        }
      )

      const compareData = await getRiverCycle(
        globalStore.selectedBasinCode,
        globalStore.sceneriesAtmosphere === 'rcp45' ? 'rcp85' : 'rcp45',
        props.periodYears,
        globalStore.units,
          props.tabSelected
      )

      chart.data.datasets[3].data = compareData.result.map((el) => getDataFromResponses(el, 'min'))
      chart.data.datasets[4].data = compareData.result.map((el) => getDataFromResponses(el, 'max'))
      chart.data.datasets[5].data = compareData.median.map((el) => getDataFromResponses(el))
    }

    chart.update()
  }
)

onMounted(() => {
  document.addEventListener('mouseover', closeChart)
})

onBeforeUnmount(() => {
  document.removeEventListener('mouseover', closeChart)
})

const csvStore = useCVSStore()

const downloadCSV = () => {
  if (!chart || !('data' in chart) || !chart.data || !chart.data.labels || !chart.data.datasets) {
    console.error('Chart is not initialized or no data available for CSV export.')
    return
  }

  const labels = chart.data.labels as string[]
  const datasets = chart.data.datasets

  // Функция для удобного форматирования сценария
  const formatRCP = (rcp: string) => {
    if (rcp === 'rcp45') return 'RCP4.5'
    if (rcp === 'rcp85') return 'RCP8.5'
    return rcp
  }

  // Определяем основной сценарий
  const baseScenario = formatRCP(globalStore.sceneriesAtmosphere || 'Scenario')

  // В зависимости от props.compare формируем столбцы
  // Структура данных:
  // При отсутствии compare (compare === null):
  // datasets[0]: Min (baseScenario)
  // datasets[1]: Max (baseScenario)
  // datasets[2]: Median (baseScenario)
  //
  // При compare === 'norm':
  // добавляется datasets[3]: Norm
  //
  // При compare === 'rcp45' или 'rcp85':
  // добавляются еще три датасета:
  // datasets[3]: Min (oppositeScenario)
  // datasets[4]: Max (oppositeScenario)
  // datasets[5]: Median (oppositeScenario)

  let header = ['Month']

  // Добавляем базовый сценарий: Min, Max, Median
  header.push(`${baseScenario} (Min)`)
  header.push(`${baseScenario} (Max)`)
  header.push(`${baseScenario} (Median)`)

  if (props.compare === 'norm') {
    header.push('Norm')
  }

  if (props.compare === 'rcp45' || props.compare === 'rcp85') {
    const opposite = globalStore.sceneriesAtmosphere === 'rcp45' ? 'RCP8.5' : 'RCP4.5'
    header.push(`${opposite} (Min)`)
    header.push(`${opposite} (Max)`)
    header.push(`${opposite} (Median)`)
  }
  header.push(`Value`)
  header.push(`Unit`)

  const rows = [header]

  // Заполняем строки по месяцам
  for (let i = 0; i < labels.length; i++) {
    const row = [labels[i]]
    // baseScenario
    row.push(datasets[0].data[i]) // Min
    row.push(datasets[1].data[i]) // Max
    row.push(datasets[2].data[i]) // Median

    if (props.compare === 'norm') {
      // Norm (4-й датасет)
      row.push(datasets[3].data[i])
    }

    if (props.compare === 'rcp45' || props.compare === 'rcp85') {
      // Датасеты для второго сценария: Min, Max, Median
      // В этом случае у нас datasets[3], datasets[4], datasets[5] - данные второго сценария
      row.push(datasets[3].data[i])
      row.push(datasets[4].data[i])
      row.push(datasets[5].data[i])
    }

    row.push(globalStore.units)
    row.push(getUnits())

    rows.push(row)
  }

  const csvContent = rows.map(e => e.join(',')).join('\n')

  // Создаем Blob и скачиваем файл
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', `${csvStore.csvName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

watch(() => csvStore.createCSV, (val) => {
  if(val){
    downloadCSV()
    csvStore.csvName = ""
    csvStore.createCSV = false
  }
})

</script>

<template>
  <div class="chart-block">
    <canvas
        :class="{
          disabled:
          (globalStore.selectedBasin === 'Всі басейни України' && props.tabSelected === 'discharge')
          || allZeros
        }"
        id="chart" ref="chartRef" width="1180" height="447" @mousemove.stop></canvas>
    <div
        class="no-data"
        :class="{
          active:
            (globalStore.selectedBasin === 'Всі басейни України' && props.tabSelected === 'discharge')
            || allZeros
        }"
    >
      {{ $t('dataPage.chart.nodata') }}
    </div>
    <div style="padding-top: 50px"></div>

    <div class="legend">
      <template v-if="props.compare === 'null' || props.compare === null">
        <div class="el median">
          <div class="el-line" style="background-color: rgba(49, 130, 189, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.median') }}</div>
        </div>

        <div class="el median">
          <div class="el-color" style="background: rgba(131, 196, 235, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.diapazonLegend') }}</div>
        </div>
      </template>

      <template v-if="props.compare === 'norm'">
        <div class="el median">
          <div class="el-line" style="background-color: rgba(49, 130, 189, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.median') }}</div>
        </div>

        <div class="el median">
          <div class="el-color" style="background: rgba(131, 196, 235, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.diapazonLegend') }}</div>
        </div>

        <div class="el median">
          <div class="el-norm">
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="el-title">{{ $t('dataPage.chart.norm') }}</div>
        </div>
      </template>

      <template v-if="props.compare === 'rcp45' || props.compare === 'rcp85'">
        <div class="el median">
          <div class="el-line" style="background-color: rgba(49, 130, 189, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.median') }} RCP8.5</div>
        </div>

        <div class="el median">
          <div class="el-line" style="background-color: #74C476"></div>
          <div class="el-title">{{ $t('dataPage.chart.median') }} RCP4.5</div>
        </div>

        <div class="el median">
          <div class="el-color" style="background: rgba(131, 196, 235, 1)"></div>
          <div class="el-title">{{ $t('dataPage.chart.diapazonLegend') }} RCP8.5</div>
        </div>

        <div class="el median">
          <div class="el-color" style="background: #BAE4B3"></div>
          <div class="el-title">{{ $t('dataPage.chart.diapazonLegend') }} RCP4.5</div>
        </div>
      </template>
    </div>

    <div class="description">
      <div v-html="$t('dataPage.chart.description1')"></div>
      <div>
        <template v-if="globalStore.selectedBasinCode !== globalStore.selectedBigBasinCode">
          {{ $t('dataPage.chart.description2household') }}
        </template>
        <template v-if="props.tabSelected === 'discharge'">
          <template v-if="globalStore.selectedBasinCode !== globalStore.selectedBigBasinCode">
            {{ $t('dataPage.chart.description2household') }}
          </template>
          <template v-else>{{ $t('dataPage.chart.description2end') }}</template>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-data {
  display: none;
}

#chart.disabled {
  opacity: 0.7;
}

.no-data.active {
  position: absolute;
  font-weight: bold;
  color: var(--color-dark-gray);
  font-size: 34px;
  top: 32.9%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
}

#chart {
  margin: 12px auto 0;
}

.description {
  margin-top: 43px;
  margin-left: 15px;
}

.chart-block {
  position: relative;
}

.legend {
  display: flex;
  justify-content: start;
  padding-left: 115px;
  //position: absolute;
  //right: 195px;
  //bottom: 70px;

  &.compare {
    right: 120px;
  }

  .el {
    display: flex;
    margin: 0 10px;
  }

  .el-line {
    height: 4px;
    width: 20px;
    margin-top: 10px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: red;
  }

  .el-color {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: red;
  }

  .el-norm {
    margin-right: 10px;

    .dot {
      display: inline-block;
      height: 3px;
      width: 9px;
      border-radius: 3px;
      background-color: black;
      margin-right: 6px;
      margin-bottom: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .median {
    .el-color {
      background-color: var(--color-primary-blue);
    }
  }
}

.flex-table {
  display: flex;
  width: 78%;
  margin: auto;
  font-size: 15px;
  margin-top: 24px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  //padding: 10px;
}

.flex-row {
  display: flex;
  margin-bottom: 5px;

  .label.body1-bold {
    //width: 30px;
    display: block;
  }
}

.flex-cell,
.label,
.value {
  //flex: 1;
  padding: 5px;
}

.label {
  padding-right: 0;
  text-align: right;
}

.value {
  padding-left: 4px;
  text-align: left;
}
</style>
