<template>
  <div class="burger">
    <div class="inner-burger focus" tabindex="1" @click="toggleBurger">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>

      <MenuList v-show="isOpen" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import MenuList from '@/UI/Header/components/Burger/MenuList.vue'

const isOpen = ref(false)

const toggleBurger = () => {
  isOpen.value = !isOpen.value
}

const closeBurger = (event) => {
  if (!event.target.closest('.burger')) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closeBurger)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeBurger)
})
</script>

<style scoped lang="scss">
.burger {
  position: relative;
  margin: 4px;
  max-height: 40px;
  max-width: 40px;
  background: var(--color-green-linear);
  border-radius: 7px;

  &:hover {
    background: var(--color-shadow16-black), var(--color-green-linear);
  }

  .inner-burger {
    padding: 14px 11px;
    margin: auto;
    cursor: pointer;
  }

  .line {
    height: 2px;
    width: 18px;
    margin-bottom: 3px;
    background-color: var(--color-primary-blue);
  }
}
</style>
