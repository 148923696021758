<template>
  <div class="dropdown" ref="dropdown">
    <button @click="openSeasonDropdown">
      <span v-if="selectedSeasonActive !== null" class="description body1 no-wrap">{{
        $t('dataPage.compare.compare')
      }}</span>

      <span class="no-wrap" style="width: 239px; display: inline-block;">
        <template v-if="selectedSeasonActive === null">{{ $t('dataPage.compare.default') }}</template>
        <template v-if="selectedSeasonActive === 'null'">{{ $t('dataPage.compare.null') }}</template>
        <template v-if="selectedSeasonActive === 'norm'">{{ $t('dataPage.compare.norm') }}</template>
        <template
            v-if="selectedSeasonActive === 'rcp' && globalStore.sceneriesAtmosphere === 'rcp85'"
        >{{ $t('dataPage.describe.rcp45Short') }}</template
        >
        <template
            v-if="selectedSeasonActive === 'rcp' && globalStore.sceneriesAtmosphere === 'rcp45'"
        >{{ $t('dataPage.describe.rcp85Short') }}</template
        >
      </span>

      <span class="icon">
        <img src="/image/dataPage/dataBlock/arrow.svg" alt="arrow" />
      </span>
    </button>
    <div v-show="showSeasons" class="dropdown-menu base-list">
      <div class="title-period body1"></div>
      <div
        class="select-button"
        :class="{
          active: selectedSeasonActive === season
        }"
        @click="selectSeason(season)"
        v-for="season in seasons"
        :key="season"
      >
        <div class="inner">
          <template v-if="season === 'null'">{{ $t('dataPage.compare.null') }}</template>
          <div v-if="season === 'norm'" :class="{
            disable: globalStore.units === 'relative' || selectedDisplayType === 'map'
          }">{{ $t('dataPage.compare.norm') }}</div>

          <template v-if="season === 'rcp' && globalStore.sceneriesAtmosphere === 'rcp85'">{{
            $t('dataPage.describe.rcp45Short')
          }}</template>
          <template v-if="season === 'rcp' && globalStore.sceneriesAtmosphere === 'rcp45'">{{
            $t('dataPage.describe.rcp85Short')
          }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, defineEmits, watch, computed } from 'vue'
import { useGlobalStore } from '@/stores/globalStore'

const props = defineProps({
  modelValue: String,
  selectedDisplayType: 'average' | 'river_cycle' | 'map'
})

const emit = defineEmits(['update:modelValue'])
const globalStore = useGlobalStore()

const seasons = ['norm', 'rcp', 'null']

const dropdown = ref(null)
const selectedSeasonObject = ref(null)
const selectedSeason = ref('null')
const selectedSeasonActive = ref(null)
const showSeasons = ref(false)

watch(selectedSeason, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(
  () => props.selectedDisplayType,
  () => {
    if (props.selectedDisplayType === 'map' && selectedSeason.value === 'norm') {
      selectedSeason.value = 'null'
    }
  }
)

watch(
  () => globalStore.units,
  (newValue) => {
    if (newValue === 'relative') {
      selectedSeasonActive.value = 'null'
      selectedSeason.value = 'null'
    }
  }
)

function openSeasonDropdown() {
  showSeasons.value = true
}

function selectSeason(season) {
  if(globalStore.units === 'relative' && season === 'norm'){
    return
  }
  selectedSeasonActive.value = season
  if (season === 'null') {
    selectedSeason.value = null
  } else if (season === 'rcp') {
    selectedSeason.value = globalStore.sceneriesAtmosphere === 'rcp85' ? 'rcp45' : 'rcp85'
  } else {
    selectedSeason.value = 'norm'
  }
}

const closeDropdown = (event) => {
  if (!dropdown.value.contains(event.target)) {
    showSeasons.value = false
    selectedSeasonObject.value = null
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.no-wrap {
  white-space: nowrap; /* Запретить перенос текста на новую строку */
  overflow: hidden; /* Скрыть содержимое, выходящее за пределы элемента */
  text-overflow: ellipsis; /* Добавить многоточие, если текст обрезается */
}

.title-period {
  color: var(--color-secondary-blue);
  padding: 35px 24px 10px;
}

.dropdown {
  z-index: 9;
  position: relative;
  display: inline-block;
  white-space: wrap;

  button {
    font-size: 16px;
    width: 280px;
    height: 50px;
    margin-left: 12px;
    margin-right: 14px;
    border: 1px solid var(--color-secondary-blue);
    color: var(--color-black);
    position: relative;
    padding: 12px;
    text-align: left;
    border-radius: 8px;
    background: var(--color-white);
    cursor: pointer;

    .description {
      position: absolute;
      top: -10px;
      background: var(--color-white);
      padding: 0 4px;
      color: #65717e;
    }

    .icon {
      position: absolute;
      right: 10px;
    }
  }
}

.base-list {
  position: absolute;
  top: 65px;
  left: 12px;
  background-color: var(--color-white);
  width: 280px;
  box-shadow: 0px 1px 3px 0px #0000004d;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .select-button {
    padding: 12px 16px;
    cursor: pointer;
    white-space: normal;
  }

  .inner {
    min-height: 24px;
    width: 93%;
    padding: 8px;
  }

  .select-button:hover .inner {
    border-radius: 8px;
    background-color: var(--color-light-gray);
  }

  .inner.active {
    background-color: var(--color-light-gray);
  }
}

.inner{
  .disable {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.sub-dropdown-menu {
  right: -305px;
}
</style>
