import axios, { type InternalAxiosRequestConfig } from 'axios'
import { getApiKey, waitForApiKey } from '../utils/cookieKeys'
import { refreshToken } from '../utils/refreshingKey'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000
})

const onRequestSuccess = async (request: InternalAxiosRequestConfig) => {
  let key = getApiKey()
  if (!key) {
    refreshToken()
    key = await waitForApiKey()
    if (!key) {
      console.error('Waiting for API key failed')
    }
  }

  if (request.url) {
    const url = new URL(request.url, request.baseURL)
    url.searchParams.set('api_key', key)
    request.url = url.toString().replace(url.origin, '')
  } else {
    console.error('Unable to retrieve API key: \n', key, '\n', request.url)
  }

  return request
}

const onResponseReject = async (error) => {
  if (!error.response) {
    console.error('Server cant send response:', error);
    return Promise.reject(new Error('Server cant send response'));
  }

  const {status} = error.response;

  const originalRequest = error.config || {};

  if (status === 403) {
    if (!originalRequest._retry) {
      console.error('Forbidden from server (403). Trying to refresh token...');
      originalRequest._retry = true; // помечаем, что повторная попытка уже была

      refreshToken();
      const newKey = await waitForApiKey();
      if (!newKey) {
        console.error('Waiting for API key failed');
        return Promise.reject(error);
      }

      console.log('new token: ', newKey)

      if (originalRequest.url) {
        const baseURL = originalRequest.baseURL || axiosInstance.defaults.baseURL;
        const url = new URL(originalRequest.url, baseURL);
        url.searchParams.set('api_key', newKey);
        originalRequest.url = url.toString().replace(url.origin, '');
      }

      try {
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error('Retrying request failed:', err);
        return Promise.reject(err);
      }
    }

    console.error('Forbidden from server (403) after retry, no more attempts.');
    return Promise.reject(error);
  }

  console.error('Error with server response:', error.response);
  return Promise.reject(error);
}

axiosInstance.interceptors.request.use(onRequestSuccess, (error) => {
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(
    (response) => {
      // Success
      return response;
    },
    onResponseReject
);

export default axiosInstance
