import axiosInstance from "@/lib/axios";
import {useGlobalStore} from "@/stores/globalStore";

const getParameterToDisplay = (selectedTab: string) => {
    const globalStore = useGlobalStore()

    if (selectedTab === 'evapotranspiration') {
        if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
        if (globalStore.evaporation === 'fact') return 'evapotranspiration'
    }

    return selectedTab
}

const getDataFromResponses = (el, tab: string): number => {
    if(!el){
        console.log('getDataFromResponses input is null: ', el, tab)
        return 0
    }

    if (getParameterToDisplay(tab) === 'water_flow') {
        return el['water_flow_outlet'] ? el['water_flow_outlet'] : el['water_flow_area']
    }

    return el[getParameterToDisplay(tab)]
}

const getData = async (
    code: string,
    source: string,
    scenario: string,
    season: string,
    year_range: string,
    value_type: string,
    area: null = null
) => {
    if (!['annual', 'spring', 'summer', 'autumn', 'winter'].includes(season.toLowerCase())) {
        season = season.charAt(0).toUpperCase() + season.slice(1)
    }

    if(area === null){
        const globalStore = useGlobalStore()
        if(globalStore.selectedBasinCode === '1' && globalStore.territory === 'UkraineOnly'){
            area = 'within'
        }
        else {
            area = 'whole'
        }
    }

    try {
        const response = await axiosInstance.get('/climate_water/', {
            params: {
                code: code,
                source: source,
                scenario: scenario,
                season: season,
                year_range: year_range,
                value_type: value_type,
                area: area
            }
        })
        return response.data
    }
    catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

export const getTableData = async (code, scenarios, season, year, units, tab) => {
    const models = [
        'median',
        'Mod1_EC-EARTH_HIRHAM5',
        'Mod2_EC-EARTH_RACMO22E',
        'Mod3_IPSL-CM5A-MR_RCA4',
        'Mod4_HadGEM-ES_RACMO22E',
        'Mod5_HadGEM2-ES_RCA4',
        'Mod6_MPI-ESM-LR_RCA4'
    ]

    const globalStore = useGlobalStore()
    let area = null
    if(tab === 'discharge' || tab === 'water_flow'){
        area = 'whole'
    }

    const requests = models.map((model) => getData(code, model, scenarios, season, year, units, area))

    const responses = await Promise.all(requests)
    return responses.map((response) => getDataFromResponses(response, tab))
}