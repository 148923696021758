<template>
  <div class="menu-block">
    <div class="menu-list">
      <div class="menu-item" v-for="menuItem in radioOptions" :key="menuItem.name">
        <div class="menu-title" @click="toggleMenu(menuItem)">
          <div class="left">
            <div class="icon">
              <img :src="menuItem.image" :alt="menuItem.storeName" />
            </div>
            <div class="headline4">
              {{ $t('header.menu.' + menuItem.name + '.name') }}
            </div>
          </div>
          <div class="right">
            <div class="icon">
              <img src="/image/menu-icons/arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
        <div class="menu-options" v-if="menuItem.isOpened">
          <div
            class="menu-radio-option form"
            v-for="radioOption in menuItem.options"
            :class="{
              inactive: radioOption.inactive
            }"
            :key="radioOption.value"
            @click="radioOption.inactive ? () => {} : menuItem.setterVal(radioOption.value)"
          >
            <input
              type="radio"
              :name="menuItem.name"
              v-model="radioOption.value"
              @change="menuItem.setterVal(radioOption.value)"
              class="form__radio-input"
            />
            <label for="radioOption.value" class="body1 form__radio-label">
              <span
                class="form__radio-button"
                :class="{
                  active: radioOption.value === globalStore[menuItem.storeName]
                }"
              ></span>
              {{ $t('header.menu.' + menuItem.name + '.options.' + radioOption.label) }}
            </label>
          </div>
        </div>
      </div>

      <div class="menu-item">
        <router-link :to="{ name: 'glossary' }">
          <div class="menu-title">
            <div class="left">
              <div class="icon">
                <img src="/image/menu-icons/golosary.svg" alt="golosary" />
              </div>
              <div class="headline4">
                {{ $t('header.menu.glossary.name') }}
              </div>
            </div>
            <div class="right"></div>
          </div>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link :to="{ name: 'data' }">
          <div class="menu-title">
            <div class="left">
              <div class="icon">
                <img src="/image/menu-icons/database.svg" alt="database" />
              </div>
              <div class="headline4">
                {{ $t('header.menu.source.name') }}
              </div>
            </div>
            <div class="right"></div>
          </div>
        </router-link>
      </div>

      <div class="menu-item">
        <router-link :to="{ name: 'how' }">
          <div class="menu-title">
            <div class="left">
              <div class="icon">
                <img src="/image/menu-icons/faq.svg" alt="faq" />
              </div>
              <div class="headline4">
                {{ $t('header.menu.howTo.name') }}
              </div>
            </div>
            <div class="right"></div>
          </div>
        </router-link>
      </div>

      <div class="menu-item">
        <div class="menu-title" @click="() => (isResetWindowOpened = true)">
          <div class="left">
            <div class="icon">
              <img src="/image/menu-icons/reset.svg" alt="reset" />
            </div>
            <div class="headline4">
              {{ $t('header.menu.reset.name') }}
            </div>
          </div>
          <div class="right"></div>
        </div>
      </div>

      <div class="menu-item" v-for="menuItem in visabilityOption" :key="menuItem.name">
        <div class="menu-title" @click="toggleMenu(menuItem)">
          <div class="left">
            <div class="icon">
              <img :src="menuItem.image" :alt="menuItem.name" />
            </div>
            <div class="headline4">
              {{ $t(menuItem.name) }}
            </div>
          </div>
          <div class="right">
            <div class="icon">
              <img src="/image/menu-icons/arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
        <div class="menu-options visably-option disable" v-if="menuItem.isOpened">
          <div class="menu-option form">
            <div class="text-size">
              <span>{{ $t('header.menu.access.options.textSize') }}</span>
              <button @click="globalStore.textSizePlus">
                <img src="./images/plus.svg" alt="plus" />
              </button>
              <button @click="globalStore.textSizeMinus()">
                <img src="./images/minus.svg" alt="minus" />
              </button>
            </div>
            <div class="menu-option">
              <div>
                <span>{{ $t('header.menu.access.options.cancel') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-model:isOpen="isResetWindowOpened" />
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useGlobalStore } from '@/stores/globalStore'
import Modal from '@/UI/Header/components/Modal/Modal.vue'

const globalStore = useGlobalStore()
const isResetWindowOpened = ref(false)

const radioOptions = reactive([
  // {
  //   storeName: 'is10Models',
  //   name: 'is10Models',
  //   image: '/image/menu-icons/amount.svg',
  //   options: [
  //     { label: 'notIs10ModelsLabel', value: false },
  //     { label: 'is10ModelsLabel', value: true }
  //   ],
  //   setterVal: globalStore.setIs10Models,
  //   value: globalStore.is10Models,
  //   isOpened: false
  // },
  {
    storeName: 'units',
    name: 'amount',
    image: '/image/menu-icons/amount.svg',
    options: [
      { label: 'variable', value: 'relative' },
      { label: 'absolute', value: 'absolute' }
    ],
    setterVal: globalStore.setUnits,
    value: globalStore.units,
    isOpened: false
  },
  {
    storeName: 'sceneriesAtmosphere',
    name: 'scenario',
    image: '/image/menu-icons/scenary.svg',
    options: [
      { label: 'SSP5', value: 'rcp85' },
      { label: 'SSP2', value: 'rcp45' }
    ],
    setterVal: globalStore.setSceneriesAtmosphere,
    value: globalStore.sceneriesAtmosphere,
    isOpened: false
  },
  {
    storeName: 'projectResearch',
    name: 'project',
    image: '/image/menu-icons/project.svg',
    options: [
      { label: 'CMIP6', value: 'CMIP6', inactive: true },
      { label: 'CORDEX', value: 'CORDEX-EUR-11' }
    ],
    setterVal: globalStore.setProjectResearch,
    value: globalStore.projectResearch,
    isOpened: false
  },
  {
    storeName: 'territory',
    name: 'area',
    image: '/image/menu-icons/territory.svg',
    options: [
      { label: 'ukraine', value: 'UkraineOnly', inactive: false },
      { label: 'transboundary', value: 'Transboundary' }
    ],
    setterVal: globalStore.setTerritory,
    value: globalStore.territory,
    isOpened: false
  },
  {
    storeName: 'rainAndTemperature',
    name: 'precipitation',
    image: '/image/menu-icons/rain.svg',
    options: [
      { label: 'reanalysis', value: 'ERA5-Land' },
      { label: 'observation', value: 'observation', inactive: true },
      { label: 'observationWind', value: 'ObservationsWind', inactive: true }
    ],
    setterVal: globalStore.setRainAndTemperature,
    value: globalStore.rainAndTemperature,
    isOpened: false
  },
  {
    storeName: 'evaporation',
    name: 'evaporation',
    image: '/image/menu-icons/vaporization.svg',
    options: [
      { label: 'actual', value: 'fact' },
      { label: 'potentially', value: 'potential' }
    ],
    setterVal: globalStore.setEvaporation,
    value: globalStore.evaporation,
    isOpened: false
  }
])
const visabilityOption = reactive([
  {
    name: 'header.menu.access.name',
    image: '/image/menu-icons/visability.svg',
    isOpened: false
  }
])

function toggleMenu(menuItem) {
  menuItem.isOpened = !menuItem.isOpened
}
</script>

<style scoped lang="scss">
.disable {
  * {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.visably-option {
  padding-left: 27px;
  .menu-option {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.text-size {
  button {
    line-height: 30px;
    font-size: 14px;
    height: 40px;
    width: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-blue);
    border-radius: 8px;
    color: var(--color-dark-gray);
    font-weight: 600;
    margin-left: 16px;

    img {
      height: 14px;
      width: 14px;
    }
  }
}

.menu-block {
  z-index: 5;
  position: absolute;
  overflow-y: scroll;
  left: -5px;
  top: 55px;
  width: 548px;
  max-height: 608px;
  border-radius: 8px;
  background-color: var(--color-white);
  //box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-gray);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
    padding: 0 5px;
  }
}

.menu-list {
  padding-top: 26px;

  .menu-title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
    }

    .right {
      margin-right: 44px;
    }
  }
}

.menu-title {
  //padding-top: 12px;
  padding-top: 15px;
  padding-bottom: 11px;
  padding-left: 25px;
  //height: 40px;
}

.headline4 {
  //height: 30px;
  line-height: 30px;
  margin-left: 10px;
}

.menu-options {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08) inset;
  background-color: var(--color-light-gray);

  .menu-radio-option {
    padding-left: 27px;

    &.inactive {
      opacity: 0.6;
      cursor: not-allowed !important;

      .form__radio-label {
        cursor: not-allowed;
      }
    }

    &:hover {
      background-color: var(--color-middle-gray);
    }
  }
}

.body1 {
  margin-left: 14px;
  line-height: 23px;
}

.form {
  &__radio-input {
    display: none;
  }

  &__radio-label {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    line-height: 48px;
  }

  &__radio-button {
    height: 20px;
    width: 20px;
    border: 3px solid var(--color-primary-blue);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    transform: translateY(-2px);

    &.active::after {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transition: opacity 0.1s;
      transform: translate(-50%, -50%);
      background-color: var(--color-primary-blue);
      opacity: 0;
    }
  }

  .form__radio-button.active:after {
    opacity: 1;
  }
}
</style>
