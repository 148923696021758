<script setup lang="ts">
</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="logo-text">
        <a href="/">C&W</a>
      </div>
      <div class="cols">
        <div class="two-cols">
          <div class="text-col">
            <div class="footer-title headline4">
              <router-link :to="{ name: 'home' }"> {{ $t('footer.mainPage') }} </router-link>
            </div>
            <div class="footer-title headline4">
              <router-link :to="{ name: 'glossary' }"> {{ $t('footer.holosary') }} </router-link>
            </div>
          </div>
          <div class="text-col">
            <div class="footer-title headline4">
              <router-link :to="{ name: 'how' }"> {{ $t('footer.howPlatform') }} </router-link>
            </div>
            <div class="footer-title headline4">
              <router-link :to="{ name: 'data' }"> {{ $t('footer.dataSources') }} </router-link>
            </div>
          </div>
        </div>
        <div class="text-col last-col">
          <div class="footer-title headline4" style="cursor: text">
            {{ $t('footer.otherPlatforms') }}
          </div>
          <div class="footer-list">
            <div class="footer-list-item label1">
              <a href="https://landwater.uhmi.org.ua/">
                Land&Water <img src="./arrow.svg" alt="arrow" />
              </a>
            </div>
            <div class="footer-list-item label1">
              <a href="https://agrostats.uhmi.org.ua/">
                Agrostats <img src="./arrow.svg" alt="arrow" />
              </a>
            </div>
            <div class="footer-list-item label1">
              <a href="https://climate.uhmi.org.ua/">
                Climate Change Viewer <img src="./arrow.svg" alt="arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  height: 240px;
  background: var(--color-blue-linear1);
  color: var(--color-white);
}

.logo-text {
  padding-top: 31px;

  a {
    color: var(--color-white);

    &:hover {
      opacity: 0.8;
    }
  }
}

.text-col {
  padding-top: 31px;
  width: 332px;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.two-cols {
  display: flex;
  justify-content: space-between;
  margin-right: 134px;
}

.cols {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;

  .footer-title {
    margin-bottom: 26px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 8px;
    }
  }

  .last-col {
    .footer-title:hover {
      text-decoration: none;
    }
  }
}

.footer-list {
  margin-top: -9px;

  .footer-list-item {
    margin-bottom: 13px;
    cursor: pointer;

    a,
    a:link,
    a:visited {
      color: white;
      text-decoration: none;
      font-weight: bold;
      position: relative;
      padding-bottom: 4px;

      img {
        transition: 0.5s;
        opacity: 0;
      }
    }

    a::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(90deg, white, white);
      background-size: 0 2px;
      background-repeat: no-repeat;
      background-position: left bottom;
      transition: background-size 0.3s ease-in;
      /* Change the transition timing above
         to determine how long the animation takes.
         Change the the timing function to adjust
         the style of the animation. */
    }

    a:hover::before {
      background-size: 100% 2px;
    }

    a:hover {
      img {
        opacity: 1;
      }
    }
  }
}
</style>
