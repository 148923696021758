<template>
  <div class="dropdown" ref="dropdown">
    <button @click="openSeasonDropdown">
      <span class="description body1">
        {{ $t('dataPage.period.period') }}
      </span>
      {{ $t('dataPage.period.' + selectedSeason) }}
      <span class="icon">
        <img src="/image/dataPage/dataBlock/arrow.svg" alt="arrow" />
      </span>
    </button>
    <div v-show="showSeasons" class="dropdown-menu base-list">
      <div class="title-period body1">
        {{ $t('dataPage.period.selectPeriod') }}
      </div>
      <div
        class="select-button"
        @click="selectSeason(season)"
        v-for="season in seasons"
        :key="season.name"
      >
        <div
          class="inner"
          :class="{
            active: selectedSeason === season.name
          }"
        >
          <img
            style="position: absolute; top: 4px; right: 10px; rotate: 180deg"
            src="/image/dataPage/dataBlock/back-arrow.svg"
            alt="back"
          />
          {{ $t('dataPage.period.' + season.name) }}
        </div>
      </div>
    </div>

    <div class="sub-dropdown-menu base-list" v-show="selectedSeasonObject">
      <div class="title-period body1">
        {{ $t('dataPage.period.selectPeriod') }}
      </div>
      <div class="select-button" @click="selectSeason('annual')">
        <div class="inner">{{ $t('dataPage.period.annual') }}</div>
      </div>
      <div class="select-button" @click="back">
        <div class="inner">
          <img
            style="position: absolute; top: 4px"
            src="/image/dataPage/dataBlock/back-arrow.svg"
            alt="back"
          />
          <span style="display: inline-block; margin-left: 50px; margin-top: 1px">{{
            $t('dataPage.period.back')
          }}</span>
        </div>
      </div>
      <div
        class="select-button"
        v-for="month in selectedSeasonObject"
        :key="month"
        @click="selectSeason(month)"
      >
        <div class="inner">{{ $t('dataPage.period.' + month) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: String
})

const emit = defineEmits(['update:modelValue'])

const seasons = [
  {
    name: 'annual'
  },
  {
    name: 'spring',
    child: ['spring', 'march', 'april', 'may']
  },
  {
    name: 'summer',
    child: ['summer', 'june', 'july', 'august']
  },
  {
    name: 'autumn',
    child: ['autumn', 'september', 'october', 'november']
  },
  {
    name: 'winter',
    child: ['winter', 'december', 'january', 'february']
  }
]
const dropdown = ref(null)
const selectedSeasonObject = ref(null)
const selectedSeason = ref(props.modelValue)
const showSeasons = ref(false)

watch(selectedSeason, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== selectedSeason.value) {
      selectedSeason.value = newValue
    }
  }
)

function openSeasonDropdown() {
  showSeasons.value = true
}

const back = () => {
  selectedSeasonObject.value = null
}

function selectSeason(season) {
  if (season.name) {
    selectedSeason.value = season.name
  } else {
    selectedSeason.value = season
  }

  if (season.child) {
    selectedSeasonObject.value = season.child
  } else {
    if (
      selectedSeason.value !== 'spring' ||
      selectedSeason.value !== 'summer' ||
      selectedSeason.value !== 'autumn' ||
      selectedSeason.value !== 'winter'
    ) {
      selectedSeasonObject.value = null
      showSeasons.value = false
    }
  }
}

const closeDropdown = (event) => {
  if (!dropdown.value.contains(event.target)) {
    showSeasons.value = false
    selectedSeasonObject.value = null
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.title-period {
  color: var(--color-secondary-blue);
  padding: 35px 24px 10px;
}

.dropdown {
  z-index: 9;
  position: relative;
  display: inline-block;

  button {
    font-size: 16px;
    width: 180px;
    border: 1px solid var(--color-secondary-blue);
    color: var(--color-black);
    position: relative;
    padding: 15px;
    text-align: left;
    border-radius: 8px;
    background: var(--color-white);
    cursor: pointer;

    .description {
      position: absolute;
      top: -10px;
      background: var(--color-white);
      padding: 0 4px;
      color: #65717e;
    }

    .icon {
      position: absolute;
      right: 10px;
    }
  }
}

.base-list {
  position: absolute;
  top: 65px;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 1px 3px 0px #0000004d;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .select-button {
    padding: 12px 16px;
    cursor: pointer;
    white-space: nowrap;
  }

  .inner {
    position: relative;
    height: 24px;
    width: 184px;
    padding: 8px;
  }

  .select-button:hover .inner {
    background-color: var(--color-light-gray);
  }

  .inner.active {
    border-radius: 8px;
    background-color: var(--color-light-gray);
  }
}

.sub-dropdown-menu {
  right: -305px;
}
</style>
