<script setup lang="ts">
import {useGlobalStore} from "@/stores/globalStore";
import {useI18n} from "vue-i18n";
import {computed, type Ref, ref, watch} from "vue";
import {getTableData} from "@/components/dataPage/components/MapComponent/api/api";

const globalStore = useGlobalStore()

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const getUnits = () => {
  let pidpis = null

  if (props.selectedTab === 'temperature') {
    pidpis = '°C'
  }

  if (currentLocale.value === 'uk') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
        props.selectedTab !== 'discharge' &&
        props.selectedTab !== 'water_flow' &&
        props.selectedTab !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
        props.selectedTab !== 'discharge' &&
        props.selectedTab !== 'water_flow' &&
        props.selectedTab !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.units === 'relative' && props.selectedTab !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}

interface Props {
  selectedTab: string
  periodYears: string
  periodSeason: string
}

const props = defineProps<Props>()
const models = ['CanESM5', 'CNRM-CM6-1', 'CNRM-ESM2-1', 'EC-Earth3', 'GFDL-ESM4', 'IPSL-CM6A-LR']

const rcp45Data: Ref<any[][]> = ref([])
const rcp85Data: Ref<any[][]> = ref([])

const riversCodes = computed(() => globalStore.selectedBasinRivers.map(river => river.code))

watch([() => props.selectedTab, () => props.periodYears, () => globalStore.units, () => globalStore.selectedBasinRivers, () => props.periodSeason, () => globalStore.territory], async () => {
  const rcp45Req = riversCodes.value.map(code => getTableData(code, 'rcp45', props.periodSeason, props.periodYears, globalStore.units, props.selectedTab))
  const rcp85Req = riversCodes.value.map(code => getTableData(code, 'rcp85', props.periodSeason, props.periodYears, globalStore.units, props.selectedTab))

  const resp45 = await Promise.all(rcp45Req)
  const resp85 = await Promise.all(rcp85Req)

  rcp45Data.value = []
  rcp85Data.value = []

  rcp45Data.value = resp45
  rcp85Data.value = resp85
}, {
  immediate: true
})

function saveToCSV(data, data2, filename) {
  const headers = "Scenario,Location,Median,Mod.1,Mod.2,Mod.3,Mod.4,Mod.5,Mod.6";

  let csvContent = headers + '\n';

  data.forEach((row, index) => {
    let rowString = `rcp45,${globalStore.selectedBasinRivers[index].name_en},${row.join(',')}`;
    csvContent += rowString + '\n';
  });

  data2.forEach((row, index) => {
    let rowString = `rcp85,${globalStore.selectedBasinRivers[index].name_en},${row.join(',')}`;
    csvContent += rowString + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename + ".csv");
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const fileName = computed(() => `${globalStore.selectedBasin.name_en}-${props.selectedTab}-${globalStore.territory.toLowerCase()}-${props.periodSeason}-${props.periodYears}-${globalStore.units}`)

const downloadTableData = () => {
  saveToCSV(rcp45Data.value, rcp85Data.value, fileName.value)
}
</script>

<template>
  <div
      style="margin-top: 80px; margin-bottom: 24px; position: relative"
  >
    <div class="download" @click="downloadTableData">
      <img src="/image/dataPage/dataBlock/download-table.svg" alt="download">
    </div>

    <div class="headline2">
      {{ $t('dataPage.describe.' + props.selectedTab) }} {{$t('dataPage.mapTable.za')}} {{ $t('dataPage.mapTable.' + props.periodSeason) }},
      {{ getUnits() }}, {{ $t('dataPage.mapTable.for') }} {{ periodYears
      }}<template v-if="currentLocale === 'uk'"> рр.</template>*
    </div>
  </div>

  <!-- RCP85 -->
  <div class="table-block">
    <div class="label1" style="margin-bottom: 12px">
      {{ $t('dataPage.mapTable.rcp85') }}
    </div>

    <table>
      <thead>
      <tr>
        <th class="body1-bold" style="vertical-align: middle; width: 540px">
          {{ $t('dataPage.mapTable.location') }}
        </th>
        <th class="body1-bold" style="vertical-align: middle">
          {{ $t('dataPage.chart.median') }}
        </th>
        <th v-for="(model, index) in models" :key="model">
          <span class="body1-bold">{{ $t('dataPage.chart.mod') }}.{{index+1}}</span><br /><span
            class="body1"
        >{{model}}</span
        >
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(river, index) in globalStore.selectedBasinRivers" :key="river.name_ua">
        <td v-if="currentLocale === 'uk'">{{ river.name_ua }}</td>
        <td v-else>{{ river.name_en }}</td>
        <td v-for="(data, indexRcp) in rcp85Data[index]" :key="indexRcp+river.name_ua+data">{{data}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <!-- RCP45 -->
  <div class="table-block">
    <div class="label1" style="margin-bottom: 12px; margin-top: 24px">
      {{ $t('dataPage.mapTable.rcp45') }}
    </div>

    <table>
      <thead>
      <tr>
        <th class="body1-bold" style="vertical-align: middle; width: 540px">
          {{ $t('dataPage.mapTable.location') }}
        </th>
        <th class="body1-bold" style="vertical-align: middle">
          {{ $t('dataPage.chart.median') }}
        </th>
        <th v-for="(model, index) in models" :key="model">
          <span class="body1-bold">{{ $t('dataPage.chart.mod') }}.{{index+1}}</span><br /><span
            class="body1"
        >{{model}}</span
        >
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(river, index) in globalStore.selectedBasinRivers" :key="river.name_ua">
        <td v-if="currentLocale === 'uk'">{{ river.name_ua }}</td>
        <td v-else>{{ river.name_en }}</td>
        <td v-for="(data, indexRcp) in rcp45Data[index]" :key="indexRcp+river.name_ua+data">{{data}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.download{
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
}

table {
  td,
  th {
    border: 2px solid #d3dce4;
    text-align: center;
    padding: 4px;
  }

  th {
    //height: 82px;

    width: 96px;
  }

  td:first-child {
    //styleName: Label 1 Bold;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: var(--LabelLargeTracking);
    text-align: left;
    color: #082f57;
  }

  tr:first-child {
    th {
      margin-top: 30px;
      width: 250px;
    }
  }
}
</style>