<template>
  <div class="dropdown" ref="dropdown">
    <button @click="openSeasonDropdown">
      <span class="download">
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M10.9997 16.3335L4.33301 9.66683L6.19967 7.7335L9.66634 11.2002V0.333496H12.333V11.2002L15.7997 7.7335L17.6663 9.66683L10.9997 16.3335ZM2.99967 21.6668C2.26634 21.6668 1.63856 21.4057 1.11634 20.8835C0.594119 20.3613 0.333008 19.7335 0.333008 19.0002V15.0002H2.99967V19.0002H18.9997V15.0002H21.6663V19.0002C21.6663 19.7335 21.4052 20.3613 20.883 20.8835C20.3608 21.4057 19.733 21.6668 18.9997 21.6668H2.99967Z"
              fill="#0B4580"
          />
        </svg>
      </span>
    </button>
    <div v-show="showDownloadList && !screenshotStore.createScreenshot" class="dropdown-menu base-list">
      <div class="title-period body1"></div>

      <div
          class="select-button"
          @click="createScreenshot('jpeg')"
      >
        <div class="inner">
          <div>{{ $t('dataPage.download') }} JPEG</div>
        </div>
      </div>

      <div
          class="select-button"
          @click="createScreenshot('png')"
      >
        <div class="inner">
          <div>{{ $t('dataPage.download') }} PNG</div>
        </div>
      </div>

      <div
          class="select-button"
          @click="createCSV"
      >
        <div class="inner">
          <div>{{ $t('dataPage.download') }} CSV</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue'
import { useGlobalStore } from '@/stores/globalStore'
import {useScreenshotStore} from "@/stores/screenshotStore";
import {useCVSStore} from "@/stores/csvStore";

const props = defineProps({
  tab: String,
  season: String,
  yearsPeriod: String
})

const globalStore = useGlobalStore()
const screenshotStore = useScreenshotStore()
const csvStore = useCVSStore()

const nameFile = computed(() => `${globalStore.selectedBasin.name_en}-${props.tab}-${globalStore.territory}-${props.season}-${props.yearsPeriod}-${globalStore.units}`)

const dropdown = ref(null)
const showDownloadList = ref(false)

const createScreenshot = (format) => {
  screenshotStore.format = format
  screenshotStore.screenshotName = nameFile.value.toLowerCase().replace(/ /g, "_")
  screenshotStore.createScreenshot = true
}

const createCSV = () => {
  csvStore.csvName = nameFile.value.toLowerCase().replace(/ /g, "_")
  csvStore.createCSV = true
}

function openSeasonDropdown() {
  showDownloadList.value = true
}

const closeDropdown = (event) => {
  if (!dropdown.value.contains(event.target)) {
    showDownloadList.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-period {
  color: var(--color-secondary-blue);
  padding: 35px 24px 10px;
}

.dropdown {
  z-index: 9;
  position: relative;
  display: inline-block;
  white-space: wrap;

  button {
    border: none;
    font-size: 16px;
    height: 50px;
    color: var(--color-black);
    position: relative;
    text-align: left;
    border-radius: 8px;
    background: var(--color-white);
    cursor: pointer;
    padding: 0;

    .download {
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      width: 50px;
      height: 38px;
      border-radius: 8px;
      background: var(--color-light-gray);
      padding: 14px;
      padding-top: 19px;
    }
  }
}

.base-list {
  position: absolute;
  top: 65px;
  right: 0px;
  background-color: var(--color-white);
  width: 280px;
  box-shadow: 0px 1px 3px 0px #0000004d;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .select-button {
    padding: 12px 16px;
    cursor: pointer;
    white-space: normal;
  }

  .inner {
    min-height: 24px;
    width: 93%;
    padding: 8px;
  }

  .select-button:hover .inner {
    border-radius: 8px;
    background-color: var(--color-light-gray);
  }

  .inner.active {
    background-color: var(--color-light-gray);
  }
}

.inner{
  .disable {
    opacity: 0.7;
    cursor: not-allowed;
  }
}


</style>
