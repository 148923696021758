<template>
  <div class="info-blocks">
    <div class="info-block">
      <div class="info-title">
        <span class="icon">
          <img
            :class="{
              low: !globalStore.logoBlockInfo.temperature.directionTop
            }"
            src="../../image/info-block-arrow.svg"
            alt="arrow"
          />
        </span>
        <span class="value subtitle1"
          >{{ ' ' }}
          {{
            globalStore.logoBlockInfo.temperature.value.toFixed(1).toString().replace('.', ',')
          }}</span
        >
        <span class="units subtitle1"> °C </span>
      </div>
      <div class="describe label1">
        {{
          globalStore.logoBlockInfo.temperature.directionTop
            ? $t('mainPage.infoBlock.growTemperature')
            : $t('mainPage.infoBlock.fallTemperature')
        }}
      </div>
    </div>
    <div class="info-block">
      <div class="info-title">
        <span class="icon">
          <img
            :class="{
              low: !globalStore.logoBlockInfo.precipitation.directionTop
            }"
            src="../../image/info-block-arrow.svg"
            alt="arrow"
          />
        </span>
        <span class="value subtitle1"
          >{{ ' ' }}
          {{ globalStore.logoBlockInfo.precipitation.value.toFixed(0).replace('-', '') }}</span
        >
        <span class="units subtitle1"> % </span>
      </div>
      <div class="describe label1">
        {{
          globalStore.logoBlockInfo.precipitation.directionTop
            ? $t('mainPage.infoBlock.growPrecipitation')
            : $t('mainPage.infoBlock.fallPrecipitation')
        }}
      </div>
    </div>
    <div class="info-block">
      <div class="info-title">
        <span class="icon">
          <img
            :class="{
              low: !globalStore.logoBlockInfo.runoff.directionTop
            }"
            src="../../image/info-block-arrow.svg"
            alt="arrow"
          />
        </span>
        <span class="value subtitle1">
          {{ ' ' }} {{ globalStore.logoBlockInfo.runoff.value.toFixed(0).replace('-', '') }}
        </span>
        <span class="units subtitle1"> % </span>
      </div>
      <div class="describe label1">
        {{
          globalStore.logoBlockInfo.runoff.directionTop
            ? $t('mainPage.infoBlock.growVolume')
            : $t('mainPage.infoBlock.fallVolume')
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/globalStore'

const globalStore = useGlobalStore()

const infoData = globalStore.logoBlockInfo
</script>

<style scoped lang="scss">
.info-blocks {
  display: flex;
  justify-content: space-between;
}

.info-block {
  width: 100%;
  background: var(--color-blue-linear1);
  height: 164px;
  border-radius: 28px;
  text-align: center;
  color: var(--color-white);
  box-shadow: 0 1px 3px 0 #0000004d;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  .info-title {
    margin-top: 25px;

    .icon {
      vertical-align: super;

      .low {
        transform: scaleY(-1);
      }
    }

    .value {
      //padding-left: 11px;
      //padding-right: 13px;
      //padding: 0 8px;
      //padding-left:5px;
    }
  }

  .describe {
    width: 228px;
    margin: 16px auto 0;
  }
}
</style>
