export const getCookieValue = (id: string): string => {
  return (
    document.cookie
      .split('; ')
      .find((row) => {
        return row.startsWith(id)
      })
      ?.split('=')[1] || ''
  )
}

export const clearCookieValue = (id: string) => {
  document.cookie = `${id}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};

export const getApiKey = (): string => {
  return getCookieValue(import.meta.env.VITE_API_TOKEN_NAME)
}

export const clearApiKey = () => {
  clearCookieValue(import.meta.env.VITE_API_TOKEN_NAME)
}

export const waitForApiKey = async (interval = 200): Promise<string> => {
  const timeout = 10000
  const endTime = Date.now() + timeout

  do {
    await new Promise((resolve) => setTimeout(resolve, interval))
  } while (Date.now() < endTime && !getApiKey())

  return getApiKey()
}

export const saveToCookie = (name: string, value: string, minutes: number) => {
  const expires = new Date()
  expires.setTime(expires.getTime() + minutes * 60 * 1000)

  const encodedValue = encodeURIComponent(value)
  document.cookie = `${name}=${encodedValue};expires=${expires.toUTCString()};path=/`
}
